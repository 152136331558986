<script setup lang="ts">
import {usePage} from "@inertiajs/vue3";
const page = usePage().props;
</script>

<template>
    <div class="min-h-screen flex flex-col justify-center items-center pt-0 px-4">
        <div class="text-center space-y-4">
            <slot name="logo"><h1 class="font-bold text-2xl">Re:gister</h1></slot >
            <slot name="title" />
        </div>

        <div class="w-full sm:max-w-[400px] mt-6 p-6 md:p-8 bg-secondary shadow-lg overflow-hidden rounded-2xl">
            <slot />
        </div>
    </div>
</template>
